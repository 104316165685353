import { Box, SystemProps, css, Grid, Flex } from "@storyofams/react-ui";
import { Image as ToolkitImage } from "@storyofams/storyblok-toolkit";
import { usePostHog } from "posthog-js/react";
import ReactPlayer from "react-player";
import { NODE_PARAGRAPH } from "storyblok-rich-text-react-renderer";

import { getLinkProps } from "~lib";

import { FixedRatio } from "~components/common/FixedRatio";
import { Button } from "../../Button";
import { Heading } from "../../Heading";
import { RichText } from "../components/RichText";
import { Container } from "../molecules/Container";

type HeroProps = {
  first?: boolean;
  content: {
    title?: string;
    text: any;
    image: any;
    button_label?: any;
    button_url?: any;
    alignContent?: boolean;
    webp_link?: string;
    second_button?: string;
    second_button_url?: string;
  };
} & SystemProps;

export const Hero = ({ content, first, ...props }: HeroProps) => {
  const posthog = usePostHog();
  return (
    <Container withOverflowHidden pt={[0, 3]} pb={0} maxWidth={"1332px"} px={2}>
      <Grid
        rowSize={[1, 2]}
        rowGap={3}
        columnGap={5}
        pt={[2, 4, 1.5]}
        pb={[2, 4, 1.5]}
      >
        <Box
          width={["100%", "50%"]}
          position="relative"
          css={css({
            img: {
              width: "100%",
              maxWidth: "100%",
              borderRadius: "md",
            },
            video: {
              borderRadius: "md",
              objectFit: "cover",
            },
          })}
        >
          {content?.webp_link ? (
            <ReactPlayer
              loop={true}
              width={"100%"}
              style={{
                backgroundImage: "none",
                objectFit: "cover",
              }}
              muted
              on
              playing
              height={"100%"}
              stopOnUnmount={true}
              playsinline
              url={content?.webp_link}
            />
          ) : !content?.image?.filename?.includes("webm") ? (
            <ToolkitImage
              alt={content?.image?.alt || ""}
              src={content?.image?.filename}
              fluid={1000}
              fit={"cover"}
              showPlaceholder={false}
            />
          ) : (
            <FixedRatio position="relative">
              <ReactPlayer
                loop={true}
                playing={true}
                width={"100%"}
                muted
                on
                playsinline
                height={"100%"}
                stopOnUnmount={false}
                url={content?.image?.filename}
              ></ReactPlayer>
            </FixedRatio>
          )}
        </Box>
        <Box
          width={["100%", "50%"]}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
        >
          <Heading
            width="100%"
            css={css({ textTransform: "none" })}
            mx="auto"
            lineHeight="medium"
            as={first ? "h1" : "h2"}
            variant={"h1"}
            {...props}
          >
            {content?.title}
          </Heading>
          <RichText
            pl={content?.alignContent ? 0 : [0, 0, 14]}
            width="100%"
            content={content}
            fontSize={"20px"}
            // nodeResolvers={{
            //   [NODE_PARAGRAPH]: (children) => (
            //     <Text variant="lg">{children}</Text>
            //   ),
            // }}
          />
          <Flex
            width={"100%"}
            justifyContent={"space-between"}
            flexDirection={["column", "row"]}
          >
            {content?.button_label && getLinkProps(content?.button_url) && (
              <Button
                width={["100%", content?.second_button ? "48%" : "100%"]}
                css={css({
                  "&&": {
                    ml: content?.alignContent ? 0 : [0, 0, 14],
                  },
                })}
                variant="primary"
                href={
                  content?.button_label !== "Persoonlijk gesprek inplannen" &&
                  getLinkProps(content?.button_url)
                }
                onClick={() => {
                  // posthog.capture("Clicked Main CTA");
                  if (content?.button_label === "Persoonlijk gesprek inplannen")
                    (window as any).boutiqAppEmbed.open(
                      "398bae0c-4c82-4a57-8df2-ae94713bbc50"
                    );
                }}
              >
                {content?.button_label}
              </Button>
            )}
            {content?.second_button && (
              <Button
                width={["100%", "48%"]}
                mt={[1, 0]}
                css={css({
                  "&&": {
                    ml: content?.alignContent ? 0 : [0, 0, 14],
                  },
                })}
                variant="primary"
                href={
                  content?.button_label !== "Persoonlijk gesprek inplannen" &&
                  getLinkProps(content?.second_button_url)
                }
                onClick={() => {
                  // posthog.capture("Clicked Main CTA");
                  if (content?.button_label === "Persoonlijk gesprek inplannen")
                    (window as any).boutiqAppEmbed.open(
                      "398bae0c-4c82-4a57-8df2-ae94713bbc50"
                    );
                }}
              >
                {content?.second_button}
              </Button>
            )}
          </Flex>
        </Box>
      </Grid>
    </Container>
  );
};

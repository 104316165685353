import { FC } from "react";
import { Box, css, Flex, SystemProps } from "@storyofams/react-ui";
import { Image as ToolkitImage } from "@storyofams/storyblok-toolkit";
import { NODE_PARAGRAPH } from "storyblok-rich-text-react-renderer";
import { RichText as RichTextComponent } from "../../RichText";
import { Text } from "../../Text";

interface RichTextProps extends SystemProps {
  text_align?: string;
  parentComponent?: string;
  first?: any;
  content?: {
    text: any;
    isCentered?: boolean;
    background?: boolean;
    add_background?: boolean;
    bg_color?: {
      color: string;
    };
    line_color?: {
      color: string;
    };
    author?: string;
    image?: {
      filename: string;
      alt: string;
    };
    is_quote_bg_enabled?: boolean;
    padding?: number;
  };
}

export const RichText: FC<RichTextProps> = ({
  content,
  text_align,
  first,
  parentComponent,
  ...props
}) => {
  if (content?.background)
    return (
      <Flex
        backgroundColor={
          content?.bg_color?.color ? content?.bg_color?.color : "#EEE3D7"
        }
        pt={parentComponent === "columns" ? ["20px", "40px"] : 0}
        px={parentComponent === "columns" ? ["20px", "40px"] : 0}
        width="100%"
      >
        <Box width={"7px"} flex="none"></Box>
        <Box display="flex" flexDirection="column">
          <RichTextComponent
            width="100%"
            css={css({ "&&": { mx: "auto" }, ".small-font": { fontSize: 2 } })}
            text={content?.text}
            {...props}
            fontSize={"18px"}
            nodeResolvers={{
              [NODE_PARAGRAPH]: (children) => (
                <Text variant="lg" pb={[2, 3]}>
                  {children}
                </Text>
              ),
            }}
          />
          {content?.image?.filename && (
            <Flex alignItems={"center"} mt={"20px"}>
              <Box
                width={"80px"}
                borderRadius="md"
                css={css({
                  img: {
                    width: "100%",
                    maxWidth: "100%",
                    borderRadius: "50%",
                  },
                })}
              >
                <ToolkitImage
                  alt={content?.image?.alt || ""}
                  src={content?.image?.filename}
                  fluid={100}
                  fit="cover"
                  height="100%"
                  showPlaceholder={false}
                />
              </Box>
              <Text fontStyle={"italic"} fontSize="20px" mb={1} ml={"18px"}>
                {content?.author}
              </Text>
            </Flex>
          )}
        </Box>
      </Flex>
    );
  return (
    <RichTextComponent
      isCentered={content?.isCentered}
      p={content?.padding || 0}
      isQuoteBg={content?.is_quote_bg_enabled}
      lineColor={content?.line_color?.color}
      backgroundColor={
        content?.add_background ? content?.bg_color?.color : "inherit"
      }
      width="100%"
      fontSize={"18px"}
      pt={parentComponent === "columns" ? ["20px", "40px"] : 0}
      px={parentComponent === "columns" ? ["20px", "40px"] : 0}
      bgColor={content?.bg_color?.color}
      css={css({ "&&": { mx: "auto" } })}
      text={content?.text}
      alignContent={text_align}
      {...props}
      nodeResolvers={{
        [NODE_PARAGRAPH]: (children) => (
          <Text fontSize={["16px", "18px"]} pb={["12px", "16px"]}>
            {children}
          </Text>
        ),
      }}
    />
  );
};
